<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Feedback Title Section -->
    <PageHeader class="pb-4" heading="Privacy Policy" sub-heading="Last updated July 28, 2022"/>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row class="mt-0" justify="center">
      <v-col class="px-0 contentMaxWidth">
        <p>Thank you for choosing to be part of our community at UofT Index (<strong>"Company," "we," "us,
          or "our"</strong>). We are committed to protecting your personal information and your right to privacy.
          If you have any questions or concerns about this privacy notice or our practices with regard to
          your personal information, please contact us at
          <a class="accent--text font-weight-medium" href="mailto:uoftindex@outlook.com">
            uoftindex@outlook.com</a></p>
        <p>This privacy notice describes how we might use your information if you:</p>
        <ul>
          <li>Visit our website at https://uoftindex.ca</li>
          <li>Engage with us in other related ways - including any sales, marketing, or events</li>
        </ul>
        <p>In this privacy notice, if we refer to:</p>
        <ul>
          <li><strong>"Website,"</strong> we are referring to any website of ours that references or links to
            this policy</li>
          <li><strong>"Services,"</strong> we are referring to our Website, and other related services, including
            any sales, marketing, or events</li>
        </ul>
        <p>The purpose of this privacy notice is to explain to you in the clearest way possible what
          information we collect, how we use it, and what rights you have in relation to it. If there are
          any terms in this privacy notice that you do not agree with, please discontinue use of our
          Services immediately.</p>
        <p class="font-weight-bold pt-3">Please read this privacy notice carefully, as it will help you understand
          what we do with the information that we collect.</p>

        <h2 class="pt-6 pb-3">1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3 class="py-2">Personal information you disclose to us</h3>
        <p>We collect personal information that you voluntarily provide to us when you register on
          the Website, express an interest in obtaining information about us or our products and
          Services, when you participate in activities on the Website or otherwise when you contact us.</p>
        <p>The personal information that we collect depends on the context of your interactions with us
          and the Website, the choices you make and the products and features you use. The personal
          information we collect may include the following:</p>
        <p><strong>Personal Information Provided by You.</strong> We collect email addresses; usernames; passwords;
          and other similar information.</p>
        <P><strong>Sensitive Information.</strong> We do not process sensitive information.</P>
        <P><strong>Social Media Login Data.</strong> We may provide you with the option to register with us using your
          existing social media account details, like your Facebook, Twitter, or other social media account. If you
          choose to register in this way, we will collect the information described in the section called
          'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.</P>
        <p>All personal information that you provide to us must be true, complete and accurate, and you
          must notify us of any changes to such personal information.</p>
        <h3 class="py-2">Information automatically collected</h3>
        <p>We automatically collect certain information when you visit, use or navigate the Website. This
          information does not reveal your specific identity (like your name or contact information) but
          may include device and usage information, such as browser and device
          characteristics, operating system, language preferences, referring URLS, device name,
          country, location, information about how and when you use our Website and other technical
          information. This information is primarily needed to maintain the security and operation of our
          Website, and for our internal analytics and reporting purposes.</p>
        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
        <p>The information we collect includes:</p>
        <ul>
          <li><strong>Usage Data.</strong> Depending on how you interact with us, this data may include your
            device information, browser type and settings and information about your activity in the Website
            (such as the date/time stamps associated with your usage, pages viewed, searches and
            other actions you take such as which features you use).</li>
          <li><strong>Device Data.</strong> We collect device data such as information about your computer,
            phone, tablet or other device you use to access the Website. Depending on the device used,
            this device data may include information such as your IP address (or proxy server)
            device and application identification numbers, location, browser type, hardware model
            Internet service provider and/or mobile carrier, operating system and system
            configuration information.</li>
          <li><strong>Location Data.</strong> We collect location data such as information about your device's
            location, which can be either precise or imprecise. How much information we collect
            depends on the type and settings of the device you use to access the Website. For
            example, we may use GPS and other technologies to collect geolocation data that tells
            us your current location (based on your IP address). You can opt out of allowing us to
            collect this information either by refusing access to the information or by disabling your
            Location setting on your device.</li>
        </ul>

        <h2 class="pt-6 pb-3">2. HOW DO WE USE YOUR INFORMATION?</h2>
        <p>We use personal information collected via our Website for a variety of business purposes
          described below. We process your personal information for these purposes in reliance on our
          legitimate business interests, in order to enter into or perform a contract with you, with your
          consent, and/or for compliance with our legal obligations. We indicate the specific processing
          grounds we rely on next to each purpose listed below.</p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li><strong>To facilitate account creation and logon process.</strong> If you choose to link your
            account with us to a third-party account (such as your Google or Facebook account), we use
            the information you allowed us to collect from those third parties to facilitate account
            creation and logon process for the performance of the contract.</li>
          <li><strong>Request feedback.</strong> We may use your information to request feedback and to contact
            you about your use of our Website.</li>
          <li><strong>To manage user accounts.</strong> We may use your information for the purposes of
            managing our account and keeping it in working order.</li>
          <li><strong>To identify usage trends.</strong> We may process information about how you use our Services to
            better understand how they are being used so we can improve them.</li>
          <li><strong>For other business purposes.</strong> We may use your information for other business
            purposes, such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and improve our Website,
            products, marketing and your experience. We may use and store this information in
            aggregated and anonymized form so that it is not associated with individual end users
            and does not include personal information.</li>
        </ul>

        <h2 class="pt-6 pb-3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
        <p>We may process or share your data that we hold based on the following legal basis:</p>
        <ul>
          <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your
            personal information for a specific purpose.</li>
          <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to
            achieve our legitimate business interests.</li>
          <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may
            process your personal information to fulfill the terms of our contract.</li>
          <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to
            do so in order to comply with applicable law, governmental requests, a judicial
            proceeding, court order, or legal process, such as in response to a court order or a
            subpoena (including in response to public authorities to meet atonal security or law
            enforcement requirements).</li>
          <li><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to
            investigate, prevent, or take action regarding potential violations of our policies,
            suspected fraud, situations involving potential threats to the safety of any person and
            illegal activities, or as evidence in litigation in which we are involved.</li>
        </ul>
        <p>More specifically, we may need to process your data or share your personal information in the
          following situations:</p>
        <ul>
          <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.</li>
          <li><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share
            your data with third-party vendors, service providers, contractors or agents who
            perform services for us or on our behalf and require access to such information to do
            that work. Examples include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow selected third parties
            to use tracking technology on the Website, which will enable them to collect data on our
            behalf about how you interact with our Website over time. This information may be
            used to, among other things, analyze and track data, determine the popularity of certain
            content, pages or features, and better understand online activity. Unless described in
            this notice, we do not share, sell, rent or trade any of your information with third parties
            for their promotional purposes.</li>
        </ul>

        <h2 class="pt-6 pb-3">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h2>
        <p>We only share and disclose your information with the following third parties. If we have processed
          your data based on your consent and you wish to revoke consent, please contact us using the contact
          details provided at the bottom of this page.</p>
        <ul>
          <li><strong>Firebase Authentication:</strong> User Account Registration and Authentication </li>
          <li><strong>Google Analytics:</strong> Web and Mobile Analytics </li>
        </ul>

        <h2 class="pt-6 pb-3">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
        <p>Our Services offer you the ability to register and log in using your third-party social media account
          details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile information we receive may vary depending
          on the social media provider concerned, but will often include your name, email address, and profile picture,
          as well as other information you choose to make public on such a social media platform.</p>
        <p>We will use the information we receive only for the purposes that are described in this privacy notice or
          that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are
          not responsible for, other uses of your personal information by your third-party social media provider. We
          recommend that you review their privacy notice to understand how they collect, use, and share your personal
          information, and how you can set your privacy preferences on their sites and apps.</p>

        <h2 class="pt-6 pb-3">6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
        <p>We use cookies and similar tracking technologies to track the activity on our Service and hold
          certain information. Tracking technologies also used are beacons, and tags to collect and track
          information and to improve and analyze our Service.</p>
        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

        <h2 class="pt-6 pb-3">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set
          out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain your data to comply with
          applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
          retained for a shorter period of time, except when this data is used to strengthen the security or
          to improve the functionality of Our Service, or We are legally obligated to retain this data for
          longer time periods.</p>

        <h2 class="pt-6 pb-3">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p>We have implemented appropriate technical and organizational security measures designed
          to protect the security of any personal information we process. However, despite our
          safeguards and efforts to secure your information, no electronic transmission over the Internet
          or information storage technology can be guaranteed to be 100% secure, so we cannot
          promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not
          be able to defeat our security, and improperly collect, access, steal, or modify your
          information. Although we will do our best to protect your personal information, transmission of
          personal information to and from our Website is at your own risk. You should only access the
          Website within a secure environment.</p>

        <h2 class="pt-6 pb-3">9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection
          laws. These may include the right (i) to request access and obtain a copy of your personal information,
          (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information;
          and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object
          to the processing of your personal information. You can make such a request by contacting us by using the
          contact details provided below.</p>
        <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
        <p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on the applicable law, you have the right
          to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using
          the contact details provided below.</p>
        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor
          when applicable law allows, will it affect the processing of your personal information conducted in reliance
          on lawful processing grounds other than consent.</p>
        <h3 class="py-2">Account Information</h3>
        <p>If you would at any time like to review or change the information in your account or terminate your account,
          you can:</p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>Upon your request to terminate your account, we will deactivate or delete your account and information from
          our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
          requirements.</p>
        <p><strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default.
          If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you
          choose to remove cookies or reject cookies, this could affect certain features or services of our
          Services.</p>

        <h2 class="pt-6 pb-3">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p>Most web browsers and some mobile operating systems and mobile applications include a
          Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not
          to have data about your online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT signals has been
          finalized. As such, we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be tracked online if a
          standard for online tracking is adopted that we must follow in the future, we will inform you
          about that practice in a revised version of this privacy notice.</p>

        <h2 class="pt-6 pb-3">11. CHILDREN'S PRIVACY AND INFORMATION</h2>
        <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
          identifiable information from anyone under the age of 13. If You are a parent or guardian and You
          are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
          that We have collected Personal Data from anyone under the age of 13 without verification of parental
          consent, We take steps to remove that information from Our servers.</p>

        <h2 class="pt-6 pb-3">12. DO WE MAKE UPDATED TO THIS NOTICE?</h2>
        <p>We may update this privacy notice from time to time. The updated version will be indicated by
          an updated "Revised" date and the updated version will be effective as soon as it is
          accessible. If we make material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending you a notification. We
          encourage you to review this privacy notice frequently to be informed of how we are
          protecting your information.</p>

        <h2 class="pt-10 pb-3">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, you can email us at
          <a class="accent--text font-weight-medium" href="mailto:uoftindex@outlook.com">
            uoftindex@outlook.com</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'Privacy',
  components: { PageHeader }
}
</script>

<style scoped>
  li {
    padding-bottom: 15px;
  }

</style>
